import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useRadioGroup } from 'common/hooks';
import moment from 'moment';
import 'moment/locale/ko';

function useSchedules({
  classInfo,
  currentSchedule = {},
  setSelectedSchedule,
}) {
    const { schedules } = classInfo ? classInfo : {};
    const timeSelector = useRadioGroup(
      currentSchedule.time ? `${currentSchedule.time}` : '',
    );
    // console.log(timeSelector.state);
    const [scheduleTime, setScheduleTime] = useState(currentSchedule);
    const [resetValue, setResetValue] = useState(0);

    const currentScheduleDate = useMemo(() => {
      if (!currentSchedule?.schedule) {
        return {};
      }

      return schedules.find((data) => {
        return data.times.find((time) =>
            time.schedule.schedule_seq === currentSchedule.schedule.schedule_seq,
        );
      });
    }, [schedules, currentSchedule]);
    
    const [scheduleDate, setScheduleDate] = useState(currentScheduleDate ? currentScheduleDate : {});

    const scheduleAmTimes = useMemo(() => {
      if (!scheduleDate.times) {
        return [];
      }
      return scheduleDate.times.filter((item) => {
        const hour = item.time.substr(0, 2);
        return parseInt(hour) < 12;
      });
    }, [scheduleDate.times]);

    const schedulePmTimes = useMemo(() => {
      if (!scheduleDate.times) {
        return [];
      }
      return scheduleDate.times.filter((item) => {
        const hour = item.time.substr(0, 2);
        return parseInt(hour) >= 12;
      });
    }, [scheduleDate.times]);

    const selectedSchedulesDateText = useMemo(() => {
      if (!scheduleTime?.schedule?.timetable) {
        return '';
      }

      let result = '';
      scheduleTime.schedule.timetable.forEach((data, index) => {
        const dateText = moment(data.schedule_date).format('M/D(dd)');
        result += index > 0 ? ', ' + dateText : dateText;
      });
      return result;
    }, [scheduleTime]);

    const selectedSchedulesTimeText = useMemo(() => {
      if (!scheduleTime?.schedule?.timetable) {
        return '';
      }

      const timeText = moment('20220101 ' + scheduleTime.time).format('A hh:mm');

      return `${timeText} ${scheduleTime.schedule.timetable.length}회`;
    }, [scheduleTime]);

    const handleDateSelect = useCallback((value) => {
        const date = value.replaceAll('-', '');
        const item = schedules.find((data) => data.date === date);
        if (!item) {
          return;
        }

        setScheduleDate(item);
        setScheduleTime({});
        timeSelector.update('');
    },[schedules, timeSelector]);

  const handleTimeChange = useCallback((e, data) => {
      if (!data) {
        return;
      }
      timeSelector.handleChange(e);
      setScheduleTime(data);
  }, [timeSelector]);

  const handleResetClick = useCallback(() => {
    if (setSelectedSchedule) {
      setSelectedSchedule('');
    }
    setScheduleDate(currentScheduleDate ? currentScheduleDate : {});
    setScheduleTime(currentSchedule);
    timeSelector.reset();
    setResetValue((prev) => prev + 1);
  }, [currentSchedule, currentScheduleDate, timeSelector, setSelectedSchedule]);

  const handlePageRefresh = useCallback(() => {
    timeSelector.reset();
    setScheduleTime({});
    setScheduleDate({});
    setResetValue((prev) => prev + 1);
  }, [timeSelector]);

  useEffect(() => {
    if (!scheduleTime || !scheduleTime.schedule) {
      return;
    }

    if (setSelectedSchedule) {
      setSelectedSchedule(scheduleTime);
    }
  }, [scheduleTime, setSelectedSchedule]);

  const events = useMemo(() => {
    if (!schedules || schedules.length === 0) {
      return [];
    }

    let validSchedules = schedules.filter((data) => {
      return parseInt(data.date) >= parseInt(moment().format('YYYYMMDD'));
    });

    validSchedules = validSchedules.filter((schedule) => {
      let valid = false;
      // console.log(schedule.times);
      schedule.times.forEach((time) => {
        // console.log(time);
        valid = time.schedule.reception_status == 1 ? true : valid;
      });
      return valid;
    });

    return validSchedules.map((data, index) => {
      return {
        title: data.date,
        start: moment(data.date).format('YYYY-MM-DD'),
        id: `${index}`,
      };
    });
  }, [schedules]);

  return {
    states: {
      events,
      scheduleDate,
      scheduleTime,
      scheduleAmTimes,
      schedulePmTimes,
      selectedSchedulesDateText,
      selectedSchedulesTimeText,
      resetValue,
    },
    actions: {
      handleDateSelect,
      handleTimeChange,
      handleResetClick,
      handlePageRefresh,
    },
    inputs: { timeSelector },
  };
}

export default useSchedules;
