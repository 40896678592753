import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  FormControl,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import detailData from 'assets/data/class-detail.json'; //상세 전체 데이터
import {
  ClassCardThumnail,
  TagList,
  TeacherInfoItem,
  PriceInfoItem,
  ClassTitle,
  SelectBox,
} from 'components/items';
import { AllMobile } from '../utils/MediaQuery';
import ClassTableThumnail from 'components/items/ClassTableThumnail';
// import 'assets/css/pages/classDDetail.scss';
import { useComboBox, useInput } from 'common/hooks';
import { useNavigate } from 'react-router';

const inquiryTypeOption = [
  {value: -1, name: "문의 유형을 선택해주세요."},
  {value: 2, name: "단순 문의"},
  {value: 3, name: "취소/환불 문의"}
];

function ClassInquiryCreate({ classData, inquiryType, inquiryContract, inquiryName, inquiryContent, orderUser }) {
  const navigate = useNavigate();
  
  let inquiryGradeOption = [];
  if(window.location.origin.includes('dev')) {
    inquiryGradeOption = [
      { value: 704, name: '예비 초등'},
      { value: 705, name: '초등 1학년'},
      { value: 706, name: '초등 2학년'},
      { value: 707, name: '초등 3학년'},
      { value: 708, name: '초등 4학년'},
      { value: 709, name: '초등 5학년'},
      { value: 710, name: '초등 6학년'}
    ];
  } else {
    if(classData.together_class_month === 1) {
      inquiryGradeOption = [
        { value: 1125, name: '예비 초등'},
        { value: 1126, name: '초등 1학년'},
        { value: 1127, name: '초등 2학년'},
        { value: 1128, name: '초등 3학년'},
        { value: 1129, name: '초등 4학년'},
        { value: 1130, name: '초등 5학년'},
        { value: 1131, name: '초등 6학년'}
      ];
    } else if(classData.together_class_month === 3) {
      inquiryGradeOption = [
        { value: 1132, name: '예비 초등'},
        { value: 1133, name: '초등 1학년'},
        { value: 1134, name: '초등 2학년'},
        { value: 1135, name: '초등 3학년'},
        { value: 1136, name: '초등 4학년'},
        { value: 1137, name: '초등 5학년'},
        { value: 1138, name: '초등 6학년'}
      ];
    } else if(classData.together_class_month === 6) {
      inquiryGradeOption = [
        { value: 1139, name: '예비 초등'},
        { value: 1140, name: '초등 1학년'},
        { value: 1141, name: '초등 2학년'},
        { value: 1142, name: '초등 3학년'},
        { value: 1143, name: '초등 4학년'},
        { value: 1144, name: '초등 5학년'},
        { value: 1145, name: '초등 6학년'}
      ];
    } else {
      inquiryGradeOption = [];
    }
  }
  const gradeType = useComboBox(classData?.class_seq);
  const orderUserOptions = useMemo(() => {
    const options = [];

    orderUser && orderUser?.map((item) => 
      options.push({
        value: item.orderSeq,
        name: `${item.contractName} / ${item.userName} / 결제번호(${item.orderNo})`
      })
    );
    options.unshift({name: "결제 정보를 선택해 주세요.", value: -1})
    return options;
  }, []);

  useEffect(() => {
    if(!classData) {
      return;
    }

    gradeType.select(classData.class_seq);
  },[gradeType]);

  const inquiryTypeChange = useCallback((e) => {
    inquiryType.select(e.target.value);
    inquiryContract.reset();
  },[inquiryType.state, inquiryContract.state]);

  const inquiryGradeChange = useCallback((e) => {
    gradeType.handleChange(e);

    if(e.target.value === 1125 || e.target.value === 1132 || e.target.value === 1139
      //  || e.target.value === 1131 || e.target.value === 1138 || e.target.value === 1145
       ) {
        showConfirm(`알림`, `
          해당 학년에 운영 중인 수업이 없습니다.
          예비 초등은 6월 이후 오픈됩니다.
        `);
        return;
    } else if(e.target.value === 704 || e.target.value === 1126 || e.target.value === 1133 || e.target.value === 1140) {
      navigate(`/togetherClass/${e.target.value}`);
      gradeState.select(e.target.value);
    } else {
      navigate(`/togetherClass/${e.target.value}/hidden`);
      gradeState.select(e.target.value);
    }
    // navigate(`/togetherClass/${e.target.value}${e.target.value !== 704 ? "/hidden" : ""}`);
  },[gradeType.state]);
  
  const inquiryContractChange = useCallback((e) => {
    inquiryContract.select(e.target.value);
  },[inquiryContract.state]);

  return (
    <>
      <div className="classInquiry-create-wrap">
        {classData.class_type !== 9 && (
          <div className="classInquiry-info-wrap">
            <AllMobile>
              <div className="modal-mobile-title">클래스 정보</div>
            </AllMobile>
            <div className="classInquiry-info-img">
              <ClassTableThumnail data={classData} />
            </div>
            <CardContent className="card-content-wrap">
              <div className="content-top">
                <TagList data={classData} />
              </div>
              <ClassTitle data={classData} title={classData.class_description} />
              <div className="content-bottom">
                <TeacherInfoItem
                  data={classData}
                  thumnail={false}
                  score={false}
                />
              </div>
            </CardContent>
          </div>
        )}
        <div className="classInquiry-info-text-wrap">
          <Grid container className="pop-table">
            {classData.class_type === 9 && (
              <>
                <Grid container className="type" alignItems="center">
                  <Grid item xs={2} className="columnLeft" alignItems="center">
                    유형<span className="important">*</span>
                  </Grid>
                  <Grid item xs={10} className="columnRight">
                    <FormControl variant="outlined" className="inquiryTypeSelect" fullWidth={true}>
                      <SelectBox options={inquiryTypeOption} value={inquiryType.state} handleChange={(e) => inquiryTypeChange(e)} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container className="type" alignItems="center" style={{ display: (classData.together_course_code === "DSTT" || classData.together_course_code === "DSKO") ? "none" : ""}}>
                  <Grid item xs={2} className="columnLeft" alignItems="center">
                    학년 정보<span className="important">*</span>
                  </Grid>
                  <Grid item xs={10} className="columnRight">
                    <FormControl variant="outlined" className="inquiryTypeSelect" fullWidth={true}>
                      <SelectBox options={inquiryGradeOption} value={gradeType.state} handleChange={(e) => inquiryGradeChange(e)} />
                    </FormControl>
                  </Grid>
                </Grid>
                {inquiryType.state === 3 && (
                  <Grid container className="type" alignItems="center">
                    <Grid item xs={2} className="columnLeft" alignItems="center">
                      결제 정보<span className="important">*</span>
                    </Grid>
                    <Grid item xs={10} className="columnRight">
                      <FormControl variant="outlined" className="inquiryTypeSelect" fullWidth={true}>
                        <SelectBox options={orderUserOptions} value={inquiryContract.state} handleChange={(e) => inquiryContractChange(e)} />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Grid container className="top" alignItems="center">
              <Grid item xs={2} className="columnLeft" alignItems="center">
                제목<span className="important">*</span>
              </Grid>
              <Grid item xs={10} className="columnRight">
                <TextField id="outlined-basic" placeholder="제목을 입력해 주세요." className="input-type1" value={inquiryName.state} onChange={inquiryName.handleChange} />
              </Grid>
            </Grid>

            <Grid container className="tr2" alignItems="center">
              <Grid item xs={2} className="columnLeft">
                내용<span className="important">*</span>
              </Grid>
              <Grid item xs={10} className="columnRight">
                <textarea aria-label="empty textarea" placeholder="내용을 입력해 주세요." className="textarea-type1" value={inquiryContent.state} onChange={inquiryContent.handleChange} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="classInquiry-notice-wrap">
          <Typography variant="h6" component="p" className="classInquiry-notice-dot">
            작성하신 문의는 마이페이지 &gt; 나의 활동 &gt; 클래스 문의에서 확인하실 수 있습니다.
          </Typography>
          <Typography variant="h6" component="p" className="classInquiry-notice-dot">
            부적절한 게시물 등록 시 무통보 삭제될 수 있습니다.
            <Typography variant="h6" component="span" className="classInquiry-notice-bar">- 개인정보를 포함하는 게시물{' '}</Typography>
            <Typography variant="h6" component="span" className="classInquiry-notice-bar">- 비방/욕설/명예훼손에 해당되는 게시물</Typography>
          </Typography>
        </div>
      </div>
    </>
  );
}

export default React.memo(ClassInquiryCreate);
