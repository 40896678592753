import { METHOD } from 'common/hooks';

export const classroomApi = (requestFunc) => {
  return {
    // 화상방
    enableClassroom: {
      key: 'enableClassroom', // 화상 수업방 접속정보 조회
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/classrooms/${params.classroom_seq}/enter-room`,
          params,
        ),
    },
    enterClassroom: {
      key: 'enterClassroom', // 수업 입장
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/classrooms/${params.classroom_seq}/enter-room`,
          params,
        ),
    },
    leaveClassroom: {
      key: 'leaveClassroom', // 수업 퇴장
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/classrooms/${params.classroom_seq}/leave-room`,
          params,
        ),
    },
  };
};
