import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { FormControl, Typography, Grid, Box } from '@material-ui/core';
import {
  Desktop,
  Mobile,
  Tablet,
  TabletAndMobile,
} from 'components/utils/MediaQuery';
import ShareIcon from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack';
import SeeMoreTeacherInfo from './SeeMoreTeacherInfo';
import Button from '@mui/material/Button';
import { heart_off_black, heart_on } from 'assets/images/icon';
import { toCurrency } from 'common/utils';
import useSchedules from 'components/calendar/hooks/useSchedules';
import useClassFavorite from './hooks/useClassFavorite';
import { useLoginPopup } from 'common/hooks';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { TimeSelect } from 'components/items';
import { Calendar, TimeSet } from '../calendar';
import { PriceInfoItem, TagList } from 'components/items';
import { icon1, icon2 } from 'assets/images/calender';
import { couponIcon } from 'assets/images/common';
import ModalShare from '../modal/ModalShare';
import { ModalWrap, ModalType1 } from '../modal';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { useLocation } from 'react-router-dom';
import { RECEPTION_STATUS } from 'common/constants';
import 'assets/css/pages/classDDetail.scss';
import { useLoginContext } from '../../common/context';
import { useCommonMutation } from 'common/hooks';
import { useApiContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import axios from "axios";
import moment from "moment";
import * as constants from "../../common/constants";

// ClassInfo.js

function ClassInfo({ data, handleApplyClass, setSelectedSchedule }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const { pathname } = useLocation();
  const calendarModal = useModalWrapper();
  const { checkLogin } = useLoginPopup();
  const { favoriteCount, favoriteYn, onAddFavorite, updateClassInfo } =
    useClassFavorite(data.class_seq, data.favorite_count, data.favorite_yn);
  const shareModalHandler = useModalWrapper();
  const { loginState } = useLoginContext();
  const {
    states: schedulesStates,
    actions: schedulesActions,
    inputs: schedulesInputs,
  } = useSchedules({
    classInfo: data,
    setSelectedSchedule: setSelectedSchedule,
  });
  const {
    events,
    scheduleDate,
    scheduleTime,
    scheduleAmTimes,
    schedulePmTimes,
    selectedSchedulesDateText,
    selectedSchedulesTimeText,
    resetValue,
  } = schedulesStates;

  // console.log('schedulesStates :', schedulesStates)
  const {
    handleDateSelect,
    handleTimeChange,
    handleResetClick,
    handlePageRefresh,
  } = schedulesActions;
  const { timeSelector } = schedulesInputs;

  // kakaopixel 상품정보
  useEffect(() => {
    const kakaoPixelScript = document.createElement('script');
    kakaoPixelScript.type = 'text/javascript';
    kakaoPixelScript.charset = 'UTF-8';
    kakaoPixelScript.src = '//t1.daumcdn.net/kas/static/kp.js';
    document.head.appendChild(kakaoPixelScript);

    let productArr = pathname.split('/');
    let getProductNum = productArr[2];
    const kakaoPixel = () => {
      if (window.kakaoPixel) {
        window.kakaoPixel('1737087911283410504').pageView();
        window.kakaoPixel('1737087911283410504').viewContent({
          id: getProductNum,
        });
      } else {
        setTimeout(kakaoPixel, 500);
      }
    };
    kakaoPixel();
  }, []);

  // Criteo 상품태그
  useEffect(() => {
    window.criteo_q = window.criteo_q || [];
    let deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent,
        )
      ? 'm'
      : 'd';
    let productArr = pathname.split('/');
    let getProductNum = productArr[2];

    let userEmail = loginState ? loginState?.profile?.email : '';
    if (getProductNum > 0) {
      window.criteo_q.push(
        { event: 'setAccount', account: 100726 },
        { event: 'setEmail', email: userEmail, hash_method: 'none' },
        { event: 'setZipcode', zipcode: '' },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewItem', item: getProductNum },
      );
    }
  }, [pathname]);
  // Criteo 상품태그 end

  // Mobon 상품태그
  useEffect(() => {
    window.ENP_VAR = {
      collect: {
        productName: data?.class_name,
        productCode: data?.class_seq,
        price: data?.curriculum_price,
        dcPrice: data?.discount_price,
      },
    };
    // window.CallMtm();
  }, []);
  // Mobon 상품태그 end

  useEffect(() => {
    if (!data) {
      return;
    }
    updateClassInfo(data.class_seq, data.favorite_count, data.favorite_yn);
  }, [data, updateClassInfo]);

  const classButtonText = useMemo(() => {
    let text = RECEPTION_STATUS.opened.text;
    if (
      data?.reception_status === undefined ||
      data?.reception_status === null
    ) {
      return text;
    }


    if (data.reception_status === RECEPTION_STATUS.awaited.value) {
      text = RECEPTION_STATUS.awaited.text;
    } else if (data.reception_status === RECEPTION_STATUS.closed.value) {
      text = RECEPTION_STATUS.closed.text;
    } else if (!events || events?.length === 0) {
      text = RECEPTION_STATUS.closed.text;
    }

    return text;
  }, [data.reception_status, events]);

  const onEnrollClass = useCallback(async () => {
    const pmUrl = constants.PM_URL;

    try {
      const response = await axios.get(pmUrl);
      const pmData = response.data;
      // JSON 데이터 활용
      const title = pmData.title;
      const message = pmData.message;
      const startDate = moment(`${pmData.fromDate} ${pmData.startTime}`, 'YYYY-MM-DD HH:mm');
      const endDate = moment(`${pmData.endDate} ${pmData.endTime}`, 'YYYY-MM-DD HH:mm');
      const nowDate = moment();

      // 현재 점검시간 일 경우
      if(nowDate.isBetween(startDate, endDate)){
        const isMobile = window.innerWidth <= 767;
        let fullMessage = '';

        if (isMobile) {
          fullMessage += `점검일자 : ${startDate._i} \n~ ${endDate._i} \n${message}`
        } else {
          fullMessage += `점검일자 : ${startDate._i} ~ ${endDate._i} \n${message}`
        }
        return showAlert(
          title,
          fullMessage,
          {},
          { width: '500px', lineHeight: '1.5' },
        );
      }

      if (checkLogin()) {
        return;
      }

      if (data?.reception_status !== RECEPTION_STATUS.opened.value) {
        return;
      }

      handleApplyClass();

    } catch (error) {
      console.error('Failed to fetch JSON data:', error);
    }
  }, [checkLogin, data?.reception_status, handleApplyClass, showAlert]);

  const onMobileEnrollClass = useCallback(async (e) => {
    const pmUrl = constants.PM_URL;

    try {
      const response = await axios.get(pmUrl);
      const pmData = response.data;
      // JSON 데이터 활용
      const title = pmData.title;
      const message = pmData.message;
      const startDate = moment(`${pmData.fromDate} ${pmData.startTime}`, 'YYYY-MM-DD HH:mm');
      const endDate = moment(`${pmData.endDate} ${pmData.endTime}`, 'YYYY-MM-DD HH:mm');
      const nowDate = moment();

      // 현재 점검시간 일 경우
      if(nowDate.isBetween(startDate, endDate)){
        const isMobile = window.innerWidth <= 767;
        let fullMessage = '';

        if (isMobile) {
          fullMessage += `점검일자 : ${startDate._i} \n~ ${endDate._i} \n${message}`
        } else {
          fullMessage += `점검일자 : ${startDate._i} ~ ${endDate._i} \n${message}`
        }
        return showAlert(
          title,
          fullMessage,
          {},
          { width: '500px', lineHeight: '1.5', fontSize: '13.5px' },
        );
      }

      if (checkLogin()) {
        return;
      }

      if (data?.reception_status !== RECEPTION_STATUS.opened.value) {
        return;
      }

      calendarModal.handleOpen(e);

    } catch (error) {
      console.error('Failed to fetch JSON data:', error);
    }
  }, [checkLogin, data?.reception_status, calendarModal, showAlert]);

/*  const onMobileEnrollClass = useCallback(
    (e) => {
      if(!shouldRedirect) {
        return pmCheck();
      }

      if (checkLogin()) {
        return;
      }

      if (data?.reception_status !== RECEPTION_STATUS.opened.value) {
        return;
      }

      calendarModal.handleOpen(e);
    },
    [checkLogin, data?.reception_status, calendarModal],
  );*/

  const { request: requestDownloadCoupons } = useCommonMutation({
    query: queries.downloadCoupons,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          let couponsInfo = '\n';
          data.result_data.forEach(
            (item) =>{              
              couponsInfo += `\ncouponItem####class####${item.coupon_name.replace(/(\r\n|\n|\r)/gm, "")} (${item.coupon_benefit.replace(/(\r\n|\n|\r)/gm, "")})`
              return couponsInfo
            }                          

          );
          showAlert('알림', `쿠폰이 다운로드 완료되었습니다.${couponsInfo?'\n'+couponsInfo:''}`);
        } else {
          console.log(data.result_message);
          showAlert('알림', '이미 다운로드된 쿠폰입니다.');
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '쿠폰 다운로드에 실패했습니다.');
      },
    },
  });

  const onDownloadCoupons = useCallback(() => {
    if (checkLogin()) {
      return;
    }
    requestDownloadCoupons({
      class_seq: data.class_seq,
    });
  }, [checkLogin, data]);

  // 2022-08-06 모바일 - 클래스신청하기 팝업 열린 후 일정 선택 알럿 높이 만큼 여백 필요 하여 정의 함
  const [scheduleAlertHeight, setScheduleAlertHeight] = useState(0);
  const scheduleAlertRef = useCallback((scheduleAlertNode) => {
    if (!scheduleAlertNode) {
      setScheduleAlertHeight(0);
    } else {
      setScheduleAlertHeight(scheduleAlertNode.getBoundingClientRect().height);
    }
  });

  useEffect(() => {
    setSelectedSchedule('');
    handlePageRefresh();
  }, [pathname]);

  const shareData = useMemo(() => {
    return {
      title: data.class_name,
      description: data.class_description,
      imageUrl: data.thumbnail_image_url,
    };
  }, [data]);

  return (
    <Grid className="categoryClassInfo">
      <Grid
        container
        className="class-info-top"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className="category-name" sx={{ pr: 2 }}>
            {data.category_name}
          </Typography>
          <TagList data={data} />
        </Box>
        <div className="btnShare">
          <ShareIcon onClick={shareModalHandler.handleOpen} />
        </div>
        {shareModalHandler.visibleModal && (
          <ModalShare
            data={shareData}
            handleClose={shareModalHandler.handleClose}
          />
        )}
      </Grid>

      <Grid className="class-info-middle">
        <Typography className="class-title" variant="h3">
          {data.class_name}
        </Typography>
        {/* <Typography className="class-summary">
          {data.class_description}
        </Typography> */}
        <ul className="class-hashtag">
          {data.keywords &&
            data.keywords.map((keyword, index) => (
              <li key={index}>{keyword}</li>
            ))}
        </ul>

        {/* 2023-04-13 기존 판매가 수정 및 쿠폰가 추가 시작 */}
        <div className="class-price-wrap">
          <Typography variant="h3" className="font-kr-bold c-sc4 fs16">
            판매가
          </Typography>
          <div className="info-price desk-info-price">
            <PriceInfoItem data={data} />
          </div>
        </div>
        
        <ul className="ref-txt-list">
          {data.worksheet_yn && <li>* 교재비 포함</li>}
          {/* 3.14 운영 미배포 */}
          {data.bookclub_point_max_rate && data.bookclub_point_max_rate > 0 ? (
            <li>* 클럽 포인트 {data.bookclub_point_max_rate}% 사용 가능</li>
          ) : ('')}
          {data.club_mileage_max_rate && data.club_mileage_max_rate > 0 ? (
            <li>* 클럽 마일리지 {data.club_mileage_max_rate}% 사용 가능</li>
          ) : ('')}
        </ul>
        {data?.max_discount_coupon && (
          <>
            <div className="class-price-wrap">
              <Typography variant="h3" className="font-kr-bold c-sc4 fs16">
                쿠폰가
              </Typography>
              <div className="info-price desk-info-price">
                {data?.max_discount_coupon.discount_type == 1 ? (
                  <span className="discount">
                    {`${toCurrency(
                      data?.max_discount_coupon.discount_amount >
                        data?.max_discount_coupon.discount_price
                        ? data?.max_discount_coupon.discount_price
                        : data?.max_discount_coupon.discount_amount,
                    )}원 할인`}
                  </span>
                ) : (
                  <span className="discount">
                    {`${parseInt(
                      data?.max_discount_coupon.discount_rate * 100,
                    )}%`}
                  </span>
                )}
                {/* // 할인율 */}

                {/* <span className="discount">
              10,000
              <span>원</span>
            </span> */}
                {/* //정액권에 대한 쿠폰가 노출시 필요한 금액 */}

                <span className="price c-ac8">
                  {toCurrency(data.max_discount_coupon.discounted_price)}
                  <span>원</span>
                </span>
                {/* // 할인 된 가격 */}
              </div>
            </div>
            {/* <ul className="ref-txt-list border-none">
              <li>* 특정 수업 시간에 적용할 수 있는 쿠폰이 있습니다.</li>
            </ul> */}
            {/* 2023-04-13 기존 판매가 수정 및 쿠폰가 추가 끝 */}

            {/* 2023-04-13 쿠폰다운로드 버튼 추가 시작 */}
            {data.downloadable_coupons.length > 0 && (
              <Button
                className="font-kr-bold btn-color-type1 fs12 w100p coupon-down-btn"
                variant="outlined"
                onClick={onDownloadCoupons}
              >
                쿠폰 다운로드
                <img src={couponIcon} alt="쿠폰 다운로드" />
              </Button>
            )}
            {/* 2023-04-13 쿠폰다운로드 버튼 추가 끝 */}
          </>
        )}

        <SeeMoreTeacherInfo data={data.teacher_info} />

        <Desktop>
          <Box className="calendar-date-wrap">
            <Typography>
              <img src={icon1} alt="캘린더" />
              클래스 시작일을 선택해 주세요.
            </Typography>
            <Box className="DateWrap calendar-wrap">
              <Calendar
                events={events}
                handleDateSelect={handleDateSelect}
                scheduleTimes={scheduleTime?.schedule?.timetable}
                resetValue={resetValue}
              />
            </Box>
            {scheduleDate?.times?.length > 0 && (
              <Box>
                <Typography>
                  <img src={icon2} alt="시간" />
                  클래스 시간을 선택해 주세요.
                </Typography>
                {scheduleAmTimes.length > 0 && (
                  <TimeSelect
                    times={scheduleAmTimes}
                    isAm={true}
                    value={timeSelector.state}
                    handleChange={handleTimeChange}
                  />
                )}
                {schedulePmTimes.length > 0 && (
                  <TimeSelect
                    times={schedulePmTimes}
                    isAm={false}
                    value={timeSelector.state}
                    handleChange={handleTimeChange}
                  />
                )}
              </Box>
            )}
            {scheduleTime?.schedule?.timetable?.length > 0 && (
              <>
                <div className="dashed-bar"></div>
                <Collapse in={true} className="schedule-alert">
                  {/* in={open} */}
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleResetClick}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography>{selectedSchedulesDateText}</Typography>
                    <Typography>{selectedSchedulesTimeText}</Typography>
                  </Alert>
                  {/* <Typography className="c-ac8 fs12 pt10">
                    이미 선택된 일정이 있습니다. 삭제 후 다시 선택해 주세요.
                  </Typography> */}
                </Collapse>
              </>
            )}
          </Box>
        </Desktop>
      </Grid>

      <Grid className="class-info-bottom">
        <div className="total-price-wrap">
          <Typography>총 결제 금액</Typography>
          <span className="price">
            {toCurrency(data.discount_price)}
            <span>원</span>
          </span>
        </div>

        <Stack direction="row" spacing={2} className="class-btnWrap">
          <Button className="favorite-count-icon wish" onClick={onAddFavorite}>
            <img
              src={favoriteYn ? heart_on : heart_off_black}
              alt="하트 이미지"
            />
            <span className="num">{favoriteCount}</span>
          </Button>
          <Desktop>
            <Button
              className={`register-class-btn cart ${
                data?.reception_status !== RECEPTION_STATUS.opened.value || events.length === 0
                  ? 'disabled-btn'
                  : ''
              }`}
              variant="contained"
              onClick={onEnrollClass}
              disabled={
                data?.reception_status !== RECEPTION_STATUS.opened.value || events.length === 0
              }
            >
              {classButtonText}
            </Button>
          </Desktop>
          {/* 2022-09-07 미디어쿼리 분리 모바일 -> 태블릿&모바일 */}
          <TabletAndMobile>
            <Button
              className={`register-class-btn cart ${
                data?.reception_status !== RECEPTION_STATUS.opened.value ||
                events.length === 0
                  ? 'disabled-btn'
                  : ''
              }`}
              variant="contained"
              onClick={onMobileEnrollClass}
              disabled={
                data?.reception_status !== RECEPTION_STATUS.opened.value ||
                events.length === 0
              }
            >
              {classButtonText}
            </Button>
            <ModalWrap
              className="option-change-btn no-padding"
              modalinfo="other-button"
              visible={calendarModal.visibleModal}
              handleOpen={calendarModal.handleOpen}
              handleClose={calendarModal.handleClose}
            >
              <ModalType1 title="수업 일정 선택">
                <div className="categoryClassInfo">
                  {/* //2022-08-06 모바일 - 클래스신청하기 팝업 열린 후 일정 선택 알럿 높이 만큼 여백 필요 하여 정의 함 */}
                  <Grid
                    className="class-info-middle"
                    style={{ paddingBottom: scheduleAlertHeight }}
                  >
                    <Box className="calendar-date-wrap calendar-date-wrap2">
                      <Typography className="caldw-p">
                        <img src={icon1} alt="캘린더" />
                        클래스 시작일을 선택해 주세요.
                      </Typography>
                      <Box className="DateWrap calendar-wrap">
                        <Calendar
                          events={events}
                          handleDateSelect={handleDateSelect}
                          scheduleTimes={scheduleTime?.schedule?.timetable}
                          resetValue={resetValue}
                        />
                      </Box>
                      {scheduleDate?.times?.length > 0 && (
                        <Box className="cal-date-time-wrap">
                          <Typography className="">
                            <img src={icon2} alt="시간" />
                            클래스 시간을 선택해 주세요.
                          </Typography>
                          {scheduleAmTimes.length > 0 && (
                            <TimeSelect
                              times={scheduleAmTimes}
                              isAm={true}
                              value={timeSelector.state}
                              handleChange={handleTimeChange}
                            />
                          )}
                          {schedulePmTimes.length > 0 && (
                            <TimeSelect
                              times={schedulePmTimes}
                              isAm={false}
                              value={timeSelector.state}
                              handleChange={handleTimeChange}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  {scheduleTime?.schedule?.timetable?.length > 0 && (
                    <>
                      <Grid
                        className="class-info-bottom class-info-bottom-mobile"
                        ref={scheduleAlertRef}
                      >
                        {/* // 2022-08-06 모바일 - 클래스신청하기 팝업 열린 후 일정 선택 알럿 높이 만큼 여백 필요 하여 정의 함 */}
                        <Collapse in={true} className="schedule-alert">
                          {/* in={open} */}
                          <Alert
                            icon={false}
                            className="mobile-alert"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleResetClick}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            <Typography>{selectedSchedulesDateText}</Typography>
                            <Typography>{selectedSchedulesTimeText}</Typography>
                          </Alert>
                          {/* <Typography className="c-ac8 fs12 pt10">
                        이미 선택된 일정이 있습니다. 삭제 후 다시 선택해 주세요.
                      </Typography> */}
                          <div className="total-price-wrap">
                            <Typography>총 결제 금액</Typography>
                            <span className="price">
                              {toCurrency(data.discount_price)}
                              <span>원</span>
                            </span>
                          </div>
                        </Collapse>

                        <Stack
                          direction="row"
                          spacing={2}
                          className="class-btnWrap"
                        >
                          <Button
                            className="favorite-count-icon"
                            onClick={onAddFavorite}
                          >
                            <img
                              src={favoriteYn ? heart_on : heart_off_black}
                              alt="하트 이미지"
                            />
                            <span className="num">{favoriteCount}</span>
                          </Button>

                          <Button
                            className="register-class-btn"
                            variant="contained"
                            onClick={onEnrollClass}
                          >
                            클래스 신청하기
                          </Button>
                        </Stack>
                      </Grid>
                    </>
                  )}
                </div>
              </ModalType1>
            </ModalWrap>
          </TabletAndMobile>
          {/* // 2022-09-07 미디어쿼리 분리 모바일 -> 태블릿&모바일 */}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default React.memo(ClassInfo);
