import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeBtn from 'assets/images/common/home_btn.png';
import backBtn from 'assets/images/common/back_btn.png';

import 'assets/css/layouts/SubHeader.scss';

const SubHeader = ({ isSearch = false, children }) => {
    const navigate = useNavigate();
    const pageState = window.history.state;

    return (
        <div className="sub-header" id="header">
            <div className="sub-header-fixed">
                <div className="search-btn-wrap">
                    <button className="back-btn" onClick={() => (pageState.idx === 0 ? navigate("/") : navigate(-1))}>
                        <img src={backBtn} alt="뒤로가기 버튼 이미지" />
                    </button>
                    <div className="sub-header-title">{children}</div>
                    <Link to="/" className="home-btn">
                        <img src={HomeBtn} alt="홈 아이콘" />
                    </Link>
                </div>
            </div>
        </div>
    );

};

export default SubHeader;

