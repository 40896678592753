import React, { useState, useMemo, useCallback } from 'react';
import Button from '@mui/material/Button';
import { Heart } from 'components/items';
import { Typography, Grid, FormControl, TextField } from '@material-ui/core';
import { file_icon } from 'assets/images/calender';
import ClassTableThumnail from 'components/items/ClassTableThumnail';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import { TagList, TeacherInfoItem, ClassTitle } from 'components/items';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context/MemberContext';
import { Desktop, TabletAndMobile, Tablet, AllMobile } from 'components/utils/MediaQuery'; //2022-07-15 미디어쿼리 추가
import { useNavigate } from 'react-router';
import useBigCalendarClassItem from './hooks/useBigCalendarClassItem';
import moment from 'moment';
import 'moment/locale/ko';
import { API_BASE_URL } from 'common/constants';
import { ModalWrap, ModalType1 } from '../modal';

import { profile_all } from 'assets/images/calender';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { useInput } from 'common/hooks';
import TogetherScheduleTable from './TogetherScheduleTable';

function BigCalendarClassItem({ key, classInfo, user, handlePostDownload }) {
  const { loginState, action } = useLoginContext();
  const { showAlert, showConfirm } = usePopAlert();
  const { buttons } = classInfo;
  const { materials, materialsModal, handleDownloadClick, handleMaterialClick, handleEnterClick, applyProfileEdit, applyScheduleEdit } =
    useBigCalendarClassItem(classInfo);
  const navigate = useNavigate();
  const together = classInfo.together;

  const scheduleModalHandler = useModalWrapper();
  const profileModalHandler = useModalWrapper();
  const profileName = useInput(
    classInfo.bookclub_profile_name !== '' ? classInfo.bookclub_profile_name : classInfo.bookclub_child_member_name
  );
  const scheduleModal = useModalWrapper();

  const [lectureSeq, setLectureSeq] = useState('');
  const [asisStudyRoomSeq, setAsisStudyRoomSeq] = useState('');
  const [tobeStudyRoomSeq, setTobeStudyRoomSeq] = useState('');
  const [changeScheduleType, setChangeScheduleType] = useState('');

  const isCanceledClass = useMemo(() => {
    return !classInfo?.order_status || classInfo.order_status === 20;
  }, [classInfo?.order_status]);

  const classEnterText = useMemo(() => {
    if (buttons?.close_yn || buttons?.finish_yn) {
      return '클래스 종료';
    }

    if (
      !classInfo.schedule_date ||
      !classInfo.schedule_time ||
      classInfo.order_status === 20 ||
      buttons?.enter_room_yn ||
      classInfo.abnormal_yn
    ) {
      return '클래스 입장';
    }

    const scheduleDate = moment(classInfo.schedule_date + ' 0000');
    const currentDate = moment(moment().format('YYYYMMDD') + ' 0000');
    if (currentDate.isSame(classInfo.schedule_date)) {
      return '클래스 입장';
    }

    const durationDays = moment.duration(scheduleDate.diff(currentDate)).asDays();

    return durationDays > 0 ? `클래스 입장 (D-${Math.ceil(durationDays)})` : '클래스 입장';
  }, [
    buttons?.close_yn,
    buttons?.finish_yn,
    buttons?.enter_room_yn,
    classInfo.schedule_date,
    classInfo.schedule_time,
    classInfo.order_status,
    classInfo.abnormal_yn,
  ]);

  const onDownloadClick = useCallback(() => {
    if (!buttons?.download_yn) {
      return;
    }

    handleDownloadClick();
  }, [classInfo.class_seq, buttons?.download_yn, handleDownloadClick]);

  const onFeedbackClick = useCallback(() => {
    if (!buttons?.feedback_yn) {
      return;
    }

    navigate(`/feedback/${classInfo.classroom_seq}`, {
      state: {
        classSeq: classInfo.class_seq,
        classmateSeq: classInfo.classmate_seq,
        userSeq: classInfo.user_seq,
      },
    });
  }, [classInfo.user_seq, classInfo.class_seq, classInfo.classmate_seq, classInfo.classroom_seq, buttons?.feedback_yn, navigate]);

  const onEnterRoomClick = useCallback(
    (e) => {
      handleEnterClick(buttons);
    },
    [handleEnterClick, buttons]
  );

  const onMaterialClick = useCallback(
    (item) => {
      const downloadMetarial = () => {
        window.open(
          `${API_BASE_URL}/classes/${classInfo.class_seq}/material-download/${item.material_file_seq}?order_seq=${classInfo.order_seq}`,
          '_blank'
        );
        // const link = document.createElement('a');
        // link.href = `${API_BASE_URL}/classes/${classInfo.class_seq}/material-download/${item.material_file_seq}?order_seq=${classInfo.order_seq}`;
        // link.rel = 'noopener noreferrer';
        // link.target = '_blank';
        // const fileName = item.file_original_name;
        // link.setAttribute('download', fileName);
        // link.click();
        // URL.revokeObjectURL(item.url);

        if (handlePostDownload) {
          handlePostDownload(classInfo.order_seq);
        }
      };

      if (buttons.downloaded_yn) {
        downloadMetarial();
      } else {
        showConfirm('알림', '수업 자료 다운로드 시 클래스 취소가 불가합니다. 자료를 다운로드 하시겠습니까?', {
          confirmHandler: downloadMetarial,
        });
      }
    },
    [buttons.downloaded_yn, classInfo.class_seq, classInfo.order_seq, handlePostDownload]
  );

  const onScheduleChange = useCallback(() => {
    scheduleModalHandler.handleOpen();
  }, []);

  const onProfileClick = useCallback(() => {
    profileModalHandler.handleOpen();
  }, []);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleProfileName = useCallback(
    (e) => {
      let name = e.target.value;
      const regex1 = /^[가-힣a-zA-Z]{1,5}$/;

      profileName.handleChange(e);

      if (name.length > 5 && profileName.state.length > 5) {
        setError(true);
        setErrorMsg('※ 5글자 이내로 입력해 주세요.');
      } else if (!regex1.test(name)) {
        setError(true);
        setErrorMsg('※ 한글, 영어 외의 다른 문자는 입력할 수 없습니다.');
      } else {
        setError(false);
        setErrorMsg('');
      }
    },
    [error, errorMsg]
  );

  const handleApplyProfile = useCallback(() => {
    if (!error) {
      applyProfileEdit(profileName.state, classInfo.order_seq);
      profileModalHandler.handleClose();
      window.location.reload();
    } else {
      showAlert('알림', '프로필명 형식을 확인 후에 다시 시도해주세요.');
    }
  }, [profileName, error]);

  const handleSchedule = useCallback(
    (changeType) => {
      scheduleModalHandler.handleClose();
      scheduleModal.handleOpen();
      setChangeScheduleType(changeType);
    },
    [changeScheduleType]
  );

  const handleChangeScheduleApply = useCallback(() => {
    applyScheduleEdit(lectureSeq, asisStudyRoomSeq, tobeStudyRoomSeq, changeScheduleType);
    scheduleModal.handleClose();
    // window.location.reload();
  }, [changeScheduleType, lectureSeq, asisStudyRoomSeq, tobeStudyRoomSeq]);

  const setTime = (time) => {
    const hour = time.substring(0, 2);
    const min = time.substring(2);

    return hour > 12 ? `오후 0${hour - 12}:${min} ` : `오전 ${hour}:${min}`;
  };

  // together courseCode CT02 / CT04는 6시간전전까지 차일 이후로 변경 가능 / 당일수업은 x
  // 수업 1시간 전 : 1 / 수업중 : 2 / 수업종료 후 : 3 (default : 0)
  const scheduleStatus = useMemo(() => {
    if (together.isTodayStudyYn == 'N' || !together.isTodayStudyTime) return 0;
    
    const format = 'HHmm';
    const nowTime = moment();
    const classTime = classInfo?.class_time;
    const isSuperPotNative =  together.courseCode == 'CT02' || together.courseCode == 'CT04';
    
    
    let isTodayStudyTime = moment(together.isTodayStudyTime, format);
    let studyBeforeTime = isTodayStudyTime.clone().subtract(isSuperPotNative ? 6 : 1, 'hours')
    let studyEndTime = isTodayStudyTime.clone().add(classTime, 'minutes')

  if (nowTime.isBefore(studyBeforeTime)) {
    return 1;
  } else if (nowTime.isBetween(studyBeforeTime, studyEndTime)) {
    return 2;
  } else if (nowTime.isAfter(studyEndTime)) {
    return 3;
  }
  }, [together.isTodayStudyTime, together.scheduleDate]);

  // 달별 제어로
  const isTodayTogetherStudy = useMemo(() => {
    return moment(together.scheduleDate).isSame(moment(), 'day')
  },[together.scheduleDate, together.isTodayStudyTime]);


  return (
    <>
      {/*  2022-11-16  수업자료 다운로드 리스트 팝업 생성 */}
      <ModalWrap
        className="option-change-btn showListModal"
        modalinfo="other-button"
        visible={materialsModal.visibleModal}
        handleOpen={materialsModal.handleOpen}
        handleClose={materialsModal.handleClose}
      >
        <ModalType1 title="수업자료">
          <ul>
            {materials?.length > 0 &&
              materials.map((item) => (
                <li key={item.material_file_seq} onClick={() => onMaterialClick(item)}>
                  <a>{item.file_original_name?.slice(0, item.file_original_name?.lastIndexOf('.'))}</a>
                </li>
              ))}
          </ul>
        </ModalType1>
      </ModalWrap>

      {/* 프로필명 변경 팝업 */}
      <ModalWrap
        className="-profileEdit"
        modalinfo="other-button"
        visible={profileModalHandler.visibleModal}
        handleOpen={profileModalHandler.handleOpen}
        handleClose={profileModalHandler.handleClose}
      >
        <ModalType1 title="프로필 수정" buttonCancel="취소" buttonApply="적용" handleApply={handleApplyProfile}>
          <>
            <TextField
              type="text"
              name="name"
              placeholder="프로필명(수정 가능)"
              variant="outlined"
              color="primary"
              value={profileName.state}
              onChange={(e) => {
                handleProfileName(e);
              }}
              style={{ width: TabletAndMobile ? '100%' : '300px', marginBottom: 20 }}
              error={error}
              helperText={errorMsg}
            />
          </>
        </ModalType1>
      </ModalWrap>

      {/* 투게더 스케줄 변경 팝업 */}
      <ModalWrap
        className="-changeSchedule"
        modalinfo="other-button"
        visible={scheduleModalHandler.visibleModal}
        handleOpen={scheduleModalHandler.handleOpen}
        handleClose={scheduleModalHandler.handleClose}
      >
      <ModalType1 title={together.isTodayStudyYn === 'Y' ? '스케줄 변경 안내' : '스케줄 조회/변경'}>
          <>
            {together.isTodayStudyYn === 'Y'  ? (
              <>
                {(() => {
                  switch (scheduleStatus) {
                    case 1:
                      return (
                        <>
                        <Typography variant="h6" component="h6" className="guide-tit">
                          다음 수업 예정일은 <br />
                          {classInfo.lep_order_no !== null ? (
                            <span>
                              {moment(isTodayTogetherStudy ? together.scheduleDate : together.nextLessonDate).format('YYYY-MM-DD(ddd)')}
                              {setTime(isTodayTogetherStudy ? together.scheduleTime : together.nextLessonTime)}
                            </span>
                          ) : (
                            <span>차일 중으로 생성될 예정</span>
                          )}
                          입니다.
                        </Typography>
                        {together.isMonthlyArrangeEnd === 'N' ? (
                          <Typography variant="h6" component="h6" className="guide-tit">
                            첫 수업일 변경은 <br />
                            <span>다음달 1일 오후 1시 이후</span>에 가능합니다.
                          </Typography>
                        ) : (
                          <div className="btnBox">
                            <Button
                              className={`changeSchedule-btn button-square-type btn-color-type2`}
                              variant="outlined"
                              onClick={() => handleSchedule('B')}
                            >
                              다음 수업만 변경
                              <TabletAndMobile>
                                <br />
                              </TabletAndMobile>
                              (1회)
                            </Button>
                            <Button
                              className={`changeSchedule-btn button-square-type btn-color-type2`}
                              variant="outlined"
                              onClick={() => handleSchedule('A')}
                            >
                              모든 수업 일정 변경
                            </Button>
                          </div>
                        )}
                      </>
                      );

                    case 2:
                      return (
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{ textAlign: 'center', color: '#1c1c1c', fontSize: '18px', padding: '30px' }}
                        >
                          수업 1시간 전부터는 일정을 바꿀 수 없습니다. {' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#1c1c1c' }}>
                          <br />
                          수업 종료 후
                          </span>
                          에 다시 시도해 주세요😥
                        </Typography>
                      );

                    case 3:
                      return (
                        <>
                        <Typography variant="h6" component="h6" className="guide-tit">
                          다음 수업 예정일은 <br />
                          {classInfo.lep_order_no !== null ? (
                            <span>
                              {moment( together.nextLessonDate).format('YYYY-MM-DD(ddd)')}
                              {setTime(together.nextLessonTime)}
                            </span>
                          ) : (
                            <span>차일 중으로 생성될 예정</span>
                          )}
                          입니다.
                        </Typography>
                        {together.isMonthlyArrangeEnd === 'N' ? (
                          <Typography variant="h6" component="h6" className="guide-tit">
                            첫 수업일 변경은 <br />
                            <span>다음달 1일 오후 1시 이후</span>에 가능합니다.
                          </Typography>
                        ) : (
                          <div className="btnBox">
                            <Button
                              className={`changeSchedule-btn button-square-type btn-color-type2`}
                              variant="outlined"
                              onClick={() => handleSchedule('B')}
                            >
                              다음 수업만 변경
                              <TabletAndMobile>
                                <br />
                              </TabletAndMobile>
                              (1회)
                            </Button>
                            <Button
                              className={`changeSchedule-btn button-square-type btn-color-type2`}
                              variant="outlined"
                              onClick={() => handleSchedule('A')}
                            >
                              모든 수업 일정 변경
                            </Button>
                          </div>
                        )}
                      </>
                      );
                  }
                })()}
              </>
            ) : (
              <>
                <Typography variant="h6" component="h6" className="guide-tit">
                  다음 수업 예정일은 <br />
                  {classInfo.lep_order_no !== null ? (
                    <span>
                      {moment(together.nextLessonDate).format('YYYY-MM-DD(ddd) ')}
                      {setTime(together.nextLessonTime)}
                    </span>
                  ) : (
                    <span>차일 중으로 생성될 예정</span>
                  )}
                  입니다.
                </Typography>
                {together.isMonthlyArrangeEnd === 'N' ? (
                  <Typography variant="h6" component="h6" className="guide-tit">
                    첫 수업일 변경은 <br />
                    <span>다음달 1일 오후 1시 이후</span>에 가능합니다.
                  </Typography>
                ) : (
                  <div className="btnBox">
                    <Button
                      className={`changeSchedule-btn button-square-type btn-color-type2`}
                      variant="outlined"
                      onClick={() => handleSchedule('B')}
                    >
                      다음 수업만 변경
                      <TabletAndMobile>
                        <br />
                      </TabletAndMobile>
                      (1회)
                    </Button>
                    <Button
                      className={`changeSchedule-btn button-square-type btn-color-type2`}
                      variant="outlined"
                      onClick={() => handleSchedule('A')}
                    >
                      모든 수업 일정 변경
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        </ModalType1>
      </ModalWrap>

      {/* 투게더 스케줄 테이블 팝업 */}
      <ModalWrap
        className="-changeScheduleTable"
        modalinfo="other-button"
        visible={scheduleModal.visibleModal}
        handleOpen={scheduleModal.handleOpen}
        handleClose={scheduleModal.handleClose}
      >
        <ModalType1
          title={`${changeScheduleType === 'B' ? '다음 수업만 변경 (1회)' : '모든 수업 일정 변경'}`}
          buttonCancel="취소"
          buttonApply="적용"
          handleApply={handleChangeScheduleApply}
        >
          <TogetherScheduleTable
            orderInfo={classInfo}
            together={classInfo.together}
            setLectureSeq={setLectureSeq}
            setAsisStudyRoomSeq={setAsisStudyRoomSeq}
            setTobeStudyRoomSeq={setTobeStudyRoomSeq}
            changeScheduleType={changeScheduleType}
            scheduleStatus={scheduleStatus}
          />
        </ModalType1>
      </ModalWrap>

      {/*  //2022-11-16  수업자료 다운로드 리스트 팝업 생성 끝 */}
      <li className="cal-list-item" key={key}>
        <div className="cal-grid">
          <Typography variant="body2" component="p" className="cal-list-p">
            <span className="cal-list-time">{moment('20220101 ' + classInfo.schedule_time).format('A hh:mm')}</span>
          </Typography>
          {classInfo.class_type !== 9 ? (
            <div className="profile-item">
              <Typography variant="body2" component="p" className="profile-p">
                {classInfo.user_name}
              </Typography>
              <div className="img">
                <img src={classInfo.user_profile_image_url} alt="학생 이름" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="cal-list-info-wrap">
          <div className="cal-list-img">
            <ClassTableThumnail data={classInfo} />
          </div>

          <div className="cal-list-text">
            <CardContent className="card-content-wrap">
              <div className="content-top">
                <TagList data={classInfo} />
              </div>
              <ClassTitle data={classInfo} />
              <div className="content-bottom">
                <TeacherInfoItem data={classInfo} showImage={false} />
              </div>
            </CardContent>
          </div>
          <div className="cal-list-button-wrap">
            {/* <div className="heart-wrap">
                      <Heart data={classInfo} />
                    </div> */}
            <div className="cal-list-button-inner">
              {classInfo.class_type !== 9 ? (
                <>
                  {/* 2022-11-16 기존 자료다운로드 미노출 후 수업자료 리스트 보여주는 버튼으로 새로 추가 함 */}
                  {!isCanceledClass && buttons?.download_yn && (
                    <Button className="cal-list-button cal-list-button-type1 cal-list-button-s1 ml0" onClick={onDownloadClick}>
                      <Desktop>
                        <img src={file_icon} alt="수업 자료" />
                      </Desktop>
                      수업 자료
                    </Button>
                  )}
                  {/* {!isCanceledClass && buttons?.download_yn && (
                      <Button
                        className="cal-list-button cal-list-button-type1 cal-list-button-s1 ml0"
                        onClick={onDownloadClick}
                      >
                        <Desktop>
                          <img src={file_icon} alt="수업 자료" />
                        </Desktop>
                        자료 다운로드
                      </Button>
                    )} */}
                  {/* 2022-11-16 수정 끝 */}
                  {!isCanceledClass && buttons?.feedback_yn && (
                    <Button className="cal-list-button cal-list-button-type1" onClick={onFeedbackClick}>
                      수업 피드백
                    </Button>
                  )}
                  {!isCanceledClass && (
                    <>
                      <Desktop>
                        <Button
                          className={`cal-list-button cal-list-button-type2 ${buttons?.enter_room_yn ? '' : 'disabled-btn'}`}
                          onClick={onEnterRoomClick}
                        >
                          {classEnterText}
                        </Button>
                      </Desktop>
                      <Tablet>
                        <Button
                          className={`cal-list-button cal-list-button-type2 ${buttons?.enter_room_yn ? '' : 'disabled-btn'}`}
                          onClick={onEnterRoomClick}
                        >
                          {classEnterText}
                        </Button>
                      </Tablet>
                      <AllMobile>
                        <Button
                          className={`cal-list-button cal-list-button-type2 ${buttons?.enter_room_yn ? '' : 'disabled-btn'}`}
                          onClick={() => {
                            if (buttons?.enter_room_yn) {
                              classInfo.class_seq === 1018 || classInfo.class_seq === 958 || classInfo.class_seq === 1054
                                ? onEnterRoomClick()
                                : showAlert('알림', '모바일에서는 입장하실 수 없습니다.');
                            }
                          }}
                        >
                          {classEnterText}
                        </Button>
                      </AllMobile>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button className={`cal-list-button cal-list-button-type2`} variant="contained" onClick={onScheduleChange}>
                    스케줄 조회/변경
                  </Button>
                  <Button className={`cal-list-button cal-list-button-type2`} variant="contained" onClick={onProfileClick}>
                    프로필 조회/수정
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        {classInfo.class_type === 9 && (
          <div className="cal-list-note" style={{ textAlign: 'left', marginTop: 10, lineHeight: 1.2 }}>
            <p style={{ fontSize: 12 }}>※ 수업 입장은 [북패드 > 투게더 수업] 메뉴에서 가능합니다.</p>
            <p style={{ fontSize: 12 }}>※ 수업 일정이 정확하지 않을 수 있으니, 북패드 내 투게더 시간표를 꼭 확인해 주세요! </p>
          </div>
        )}
      </li>
    </>
  );
}

export default BigCalendarClassItem;
