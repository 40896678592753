import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useAppSettings } from 'common/hooks';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'assets/css/layouts/gnb.scss';

// gnb.js

function Gnb({ cate, menu, top }) {
    const [loginChk, setLoginChk] = useState(false);
    const { pathname } = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [currentLink, setCurrentLink] = useState('');
    const { screenTypes } = useAppSettings();
    const [swiper, setSwiper] = useState(null);
    
    useEffect(() => {
        const url = document.location.href;
        const splitUrl = url.split('/');
        const location = splitUrl[splitUrl.length - 1];
        setCurrentLink(location);
        let p = document.getElementsByClassName('category-box');
        if (p && p.length > 0) {
            if (p[0].closest('.mo-header') !== null) mCategoryClose();
            else categoryClose();
        }
    }, [pathname]);

    useEffect(() => {
        if (currentLink !== '') {
            setTimeout(() => {
                window.ENP_VAR = {};
                // window.CallMtm();
            },1000)
        }
    },[currentLink])
    /* 2022-08-08 끝 */

    const categoryMenu = useRef();
    const categoryBox = useRef();
    const mCategoryMenu = useRef();
    const mCategoryMenuWrap = useRef();
    const mDep2CategoryMenu = useRef();

    const categorySwitch = () => {
        if (!categoryMenu.current.classList.contains('on')) {
            categoryOpen();
        } else {
            categoryClose();
        }
    }
    const mCategorySwitch = () => {
        if (!mCategoryMenuWrap.current.classList.contains('on')) {
            mCategoryOpen();
        } else {
            mCategoryClose();
        }
    }

    const categoryOpen = () => {
        categoryMenu.current.classList.add('on');
    };
    const categoryClose = () => {
        categoryMenu.current.classList.remove('on');
        categoryDep2Close();
    };

    const mCategoryOpen = () => {
        mCategoryMenuWrap.current.classList.add('on');
        mDep2CategoryInit();
    };
    const mCategoryClose = () => {
        mCategoryMenuWrap.current.classList.remove('on');
    };

    const categoryDep2Switch = (e) => {
        if (!e.target.classList.contains('dep2on')) {
            document.querySelector('.btn.dep2on')?.classList.remove('dep2on');
            e.target.classList.add('dep2on');
            categoryMenu.current.classList.add('extend');
            document.querySelector('.btn.active')?.classList.remove('active');
        } else {
            categoryDep2Close();
        }
    };
    const categoryDep2Close = () => {
        document.querySelector('.btn.dep2on')?.classList.remove('dep2on');
        categoryMenu.current.classList.remove('extend');
    };

    useEffect(() => {
        categoryBox.current.addEventListener('mouseleave', function (e) {
            if (e.target.closest('.mo-header') === null) {
                categoryClose();
            } else {
                mCategoryClose();
            }
        });

        mDep2CategoryMenu.current?.addEventListener('scroll', function (e) {
            let scrollPos = e.target.scrollTop;
            e.target.childNodes.forEach((element, index) => {
                let sectionTop = element.offsetTop;
                let sectionBottom = sectionTop + element.clientHeight;
        
                if (scrollPos >= sectionTop && scrollPos <= sectionBottom) {
                    mCategoryMenu.current.childNodes[index].childNodes[0].classList.add('active');
                } else {
                    mCategoryMenu.current.childNodes[index].childNodes[0].classList.remove('active');
                }
            })
        })

        mCategoryMenu.current?.querySelectorAll('.btn.arr').forEach((element) => {
            element.addEventListener('click', function (e) {
                let parent = e.target.parentNode;
                let moveIndex = Array.from(parent.parentNode.children).indexOf(parent);
                let movePos = mDep2CategoryMenu.current.childNodes[moveIndex].offsetTop;
                mDep2CategoryMenu.current.scrollTo({
                    left :0,
                    top: movePos,
                    behavior: "smooth"
                });  
            })
        });
    }, []);

    const mDep2CategoryInit = () => {
        mDep2CategoryMenu.current.scrollTo(0, 0);
        mCategoryMenu.current.childNodes[0].childNodes[0].classList.add('active');
    };

    const swiperParams = {
        onSwiper: setSwiper,
        slidesPerView: 'auto',
    };

    return (
        <>
            <Desktop>
                <div className="gnb-area">
                    <div className="container">
                        <div className="category-box" ref={categoryBox}>
                            <Button className="category-btn" onClick={categorySwitch}>카테고리</Button>
                            <ul className="category-menu" ref={categoryMenu}>
                                {cate && cate?.map((item, idx) => 
                                    <li>
                                        <button className="btn arr" onClick={categoryDep2Switch}>{item.category_name}</button>
                                        <div className="category-dep2-area">
                                            <ul className="category-dep2-menu">
                                                <li>
                                                    <NavLink to={item.category_seq === 10 ? "/class/" : item.category_seq === 30 ? "/event/" : "/togetherClass/"} className="btn">ALL</NavLink>
                                                </li>
                                                {item.childs.map((link, index) => 
                                                    <li key={index}>
                                                        <NavLink to={(item.category_seq === 10 ? "/class/best/" : item.category_seq === 30 ? "/event/category/" : "/togetherClass/best/") +link.category_seq} className="btn">{link.category_name}</NavLink>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </li>
                                )}
                                {menu && menu?.map((item) => 
                                    <li key={item.main_menu_seq}><NavLink to={item.link_url} className="btn">{item.menu_title}</NavLink></li>
                                )}
                            </ul>
                        </div>
                        <Swiper
                            // slidesPerView={'auto'}
                            // spaceBetween={16}
                            {...swiperParams}
                            ref={setSwiper}
                            className="gnb"
                            // className={
                            //   users?.length >= 6 || users?.length == 5 ? 'flexStart' : 'flexEnd'
                            // }
                            style={{
                                width: "auto !important",
                                height: "63px",
                            }}
                        >
                            {top && top?.map((item, idx) =>
                                item.exposure_yn === 1 ? (
                                    <SwiperSlide key={idx}>
                                        <NavLink to={item.link_url}>{item.menu_title}</NavLink>
                                    </SwiperSlide>
                                ) : (<></>)
                            )}
                        </Swiper>
                    </div>
                </div>
            </Desktop>

            <TabletAndMobile>
                <div className="gnb-area">
                    <div className="container">
                        <div className="category-box" ref={categoryBox}>
                            <Button className="category-btn" onClick={mCategorySwitch}></Button>
                            <div className="category-menu-wrap" ref={mCategoryMenuWrap}>
                                <ul className="category-menu" ref={mCategoryMenu}>
                                    {cate && cate?.map((item, idx) => 
                                        <li key={idx}>
                                            <button className="btn arr">{item.category_name}</button>
                                        </li>
                                    )}
                                    {menu && menu?.map((item) => 
                                        <li key={item.main_menu_seq}><Link to={item.link_url} className="btn">{item.menu_title}</Link></li>
                                    )}
                                </ul>

                                <div className="m-dep2-category-menu" ref={mDep2CategoryMenu}>
                                    {cate && cate?.map((item, idx) => 
                                        <div className="category-dep2-area">
                                            <Link to={item.category_seq === 10 ? "/class/" : item.category_seq === 30 ? "/event/" : "/togetherClass"} className="tit-link">{item.category_name}</Link>
                                            <ul className="category-dep2-menu">
                                                <li><Link to={item.category_seq === 10 ? "/class/" : item.category_seq === 30 ? "/event/" : "/togetherClass"} className="btn">ALL</Link></li>
                                                {item.childs.map((link, index) => 
                                                    <li key={index}>
                                                        <Link to={(item.category_seq === 10 ? "/class/best/" : item.category_seq === 30 ? "/event/category/" : "/togetherClass/best/") +link.category_seq} className="btn">{link.category_name}</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Swiper
                            {...swiperParams}
                            ref={setSwiper}
                            className="gnb"
                        >
                            {top && top?.map((item, idx) =>
                                item.exposure_yn === 1 ? (
                                    <SwiperSlide key={idx}>    
                                        <NavLink to={item.link_url}>{item.menu_title}</NavLink>
                                    </SwiperSlide>
                                ) : (<></>)
                            )}
                        </Swiper>
                    </div>
                </div>
            </TabletAndMobile>
        </>
    );
}
export default React.memo(Gnb);
