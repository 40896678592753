import React, { useState, useEffect, useCallback } from 'react';
import BigCalendarClassItem from './BigCalendarClassItem';
// import useMyClassItem from './hooks/useMyClassItem';

// BigCalenderListWrap.js

function BigCalendarListWrap({ classes, user, handlePostDownload }) {

  return (
    <ul className="cal-list-wrap">
      {classes && classes.map((classInfo) => (
          <BigCalendarClassItem
            key={`${classInfo.timetable_seq}_${classInfo.user_seq}_${classInfo.order_seq}`}
            classInfo={classInfo}
            user={user}
            handlePostDownload={handlePostDownload}
          />
        ))}
    </ul>
  );
}

export default React.memo(BigCalendarListWrap);
