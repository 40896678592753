import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useCommonMutation, useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context/MemberContext';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { DOMAIN } from 'common/constants';

function useBigCalendarClassItem(classInfo) {
  const { loginState } = useLoginContext();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const materialsModal = useModalWrapper();
  const [materials, setMaterials] = useState([]);
  const [fileInfo, setFileInfo] = useState(undefined);
  const [profileName, setProfileName] = useState("");

  const { request: requestEnableClassroom } = useCommonQuery({
    query: queries.enableClassroom,
    params: {
      classroom_seq: classInfo?.classroom_seq,
      classmate_seq: classInfo?.classmate_seq,
      timetable_seq: classInfo?.timetable_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          showConfirm('알림', '수업에 입장하시겠습니까?', {
            confirmHandler: () =>
              window.open(
                `${DOMAIN}/room/u/${data.result_data.classroom_seq}/${data.result_data.classmate_seq}?accessToken=${data.result_data.token}&ts=${data.result_data.timetable_seq}`,
                '_blank',
              ),
          });
        } else {
          console.log(data.result_message);
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '수업 입장에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestMetarials } = useCommonQuery({
    query: queries.classMaterials,
    params: {
      class_seq: classInfo?.class_seq,
      classroom_seq: classInfo?.classroom_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setMaterials(data.result_data);
          materialsModal.handleOpen();
        } else {
          // console.log(data.result_message);
          showAlert('알림', data.result_message);
          setMaterials([]);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '수업자료 조회에 실패했습니다.');
        setMaterials([]);
      },
    },
    initEnabled: false,
  });

  const { request: requestDownloadMaterial } = useCommonQuery({
    query: queries.downloadClassMaterial,
    params: {
      class_seq: classInfo?.class_seq,
      file_seq: fileInfo?.material_file_seq,
      classroom_seq: classInfo?.classroom_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileInfo.file_original_name);
        link.click();
        URL.revokeObjectURL(url);
        setFileInfo(undefined);
      },
      onError: (error) => {
        setFileInfo(undefined);
        showAlert('알림', '다운로드에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  /* 투게더용 프로필명 변경 */
  const { request: requestProfileEdit } = useCommonMutation({
    query: queries.togetherProfileEdit,
    callbacks: {
      onSuccess: (data) => {
        console.log("data :: ", data.result_code);
      },
      onError: (error) => {
        console.log("error :: ", error);
      },
    },
  });

  /* 투게더용 스케줄 변경 */
  const { request: requestScheduleChange } = useCommonMutation({
    query: queries.togetherSchedulesChangeApply,
    callbacks: {
      onSuccess: (data) => {
        if(data.result_code === "0000") {
          if(data.result_data.resultCode === "200") {
            showAlert('알림', data.result_data.resultMsg,{
              confirmHandler: () => {
                window.location.reload();
              }});
          } else {
            showAlert('알림', data.result_data.resultMsg);
          }
        }
      },
      onError: (error) => {
        console.log("error :: ", error);
      },
    },
  });

  // const { request: requestDownloadMaterials } = useCommonQuery({
  //   query: queries.classroomDownloadMaterials,
  //   params: {
  //     class_seq: fileClassSeq,
  //   },
  //   callbacks: {
  //     onSuccess: (data) => {
  //       setFileClassSeq('');
  //       // console.log(data);
  //       const url = URL.createObjectURL(new Blob([data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       const fileName = `${classInfo.class_name.replaceAll(
  //         ' ',
  //         '_',
  //       )}_자료.zip`;
  //       link.setAttribute('download', fileName);
  //       // document.body.appendChild(link);
  //       link.click();
  //       // document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     },
  //     onError: (error) => {
  //       // console.log(error);
  //       setFileClassSeq('');
  //       showAlert('알림', '다운로드에 실패했습니다.');
  //     },
  //   },
  //   initEnabled: false,
  // });

  useEffect(() => {
    if (!fileInfo) {
      return;
    }
    requestDownloadMaterial();
  }, [fileInfo]);

  const handleDownloadClick = useCallback((classSeq) => {
    setMaterials([]);
    setFileInfo(undefined);
    requestMetarials();
  }, []);

  const handleMaterialClick = useCallback((fileInfo) => {
    setFileInfo(fileInfo);
  }, []);

  const specificClassUrls = {
    791: 'https://www.youtube.com/live/lmooC9uejeM?feature=share',
    848: 'https://biz.gooroomee.com/라이브올특강-이대훈선수',
    663: 'https://biz.gooroomee.com/라이브올특강-이대훈선수',
    840: 'https://youtube.com/live/DANZCkG12Vo?feature=share',
    849: 'https://youtube.com/live/DANZCkG12Vo?feature=share',
    773: 'https://youtube.com/live/VXdD_9PN_aU?feature=share',
    912: 'https://youtube.com/live/VXdD_9PN_aU?feature=share',
    958: 'https://www.youtube.com/watch?v=qtwJDDR028g',
    959: 'https://biz.gooroomee.com/라이브올-마술특강',
    1054: 'https://youtube.com/live/leBm5fb6nJE?feature=share',
    1018: 'https://youtube.com/live/leBm5fb6nJE?feature=share'
  };

  const handleEnterClick = useCallback((buttons) => {
    let classUrl = specificClassUrls[classInfo?.class_seq];

    if (buttons?.enter_room_yn) {
      if (!classInfo?.classroom_seq || !classInfo?.classmate_seq) {
        showAlert('알림', '수업에 입장할 수 없습니다.');
      }

      if (classUrl != undefined) {
        showConfirm('알림', '수업에 입장하시겠습니까?', {
          confirmHandler: () =>
              window.open(
                  classUrl,
                  '_blank',
              ),
        });
      } else {
        requestEnableClassroom();
      }
    }
    classUrl = classUrl || "일반클래스";
    console.log("[class url] " + classUrl);
  }, [
    classInfo?.classmate_seq,
    classInfo?.classroom_seq,
    classInfo?.class_seq,
    requestEnableClassroom,
    showAlert,
  ]);

  const applyProfileEdit = useCallback((name, orderSeq) => {
    setProfileName(name);
    requestProfileEdit({order_seq: orderSeq, bookclubProfileName: name});
  });

  const applyScheduleEdit = useCallback((lectureSeq, asis, tobe, change_type) => {
    /* console.log(`useBigCalendarClassItem --> applyScheduleEdit
      lecture_seq: ${lectureSeq},
      asis_study_room_seq: ${asis},
      tobe_study_room_seq: ${tobe},
      change_type: ${change_type}
    `) */
    requestScheduleChange({
      lecture_seq: lectureSeq,
      asis_study_room_seq: asis,
      tobe_study_room_seq: tobe,
      change_type: change_type,
    });
  });

  return {
    materials,
    materialsModal,
    handleDownloadClick,
    handleMaterialClick,
    handleEnterClick,
    applyProfileEdit,
    applyScheduleEdit 
  };
}

export default useBigCalendarClassItem;
